import React, { useCallback, useState } from "react";
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  FloatingMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import { Button } from "react-bootstrap";
import { useS3Upload } from "next-s3-upload";
import { Image } from "@tiptap/extension-image";
import imageResizer, { dataURIToFile } from "../utils/imageResizer";
import ImageCrop from "./ImageCrop";
import Link from "@tiptap/extension-link";
import { LinkModal } from "./LinkModal";
import Global from "../utils/globalEvents";
import IconButton from "./IconButton";
import AlertModal from "./AlertModal";
import Youtube from "@tiptap/extension-youtube";

const textIcons = {
  list: (
    <svg
      height="14"
      width="14"
      fill="currentcolor"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 17h-7a2 2 0 0 0 0 4h7a2 2 0 0 0 0-4zM19 10h-7a2 2 0 0 0 0 4h7a2 2 0 0 0 0-4zM19 3h-7a2 2 0 0 0 0 4h7a2 2 0 0 0 0-4z" />
      <circle cx="5" cy="19" r="2.5" />
      <circle cx="5" cy="12" r="2.5" />
      <circle cx="5" cy="5" r="2.5" />
    </svg>
  ),
  bold: (
    <svg
      height="12"
      width="12"
      fill="currentcolor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 19V1h8a5 5 0 0 1 3.88 8.16A5.5 5.5 0 0 1 11.5 19H3zm7.5-8H7v5h3.5a2.5 2.5 0 1 0 0-5zM7 4v4h3a2 2 0 1 0 0-4H7z" />
    </svg>
  ),
  heading: (
    <svg
      height="12"
      width="12"
      fill="currentcolor"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z" />
    </svg>
  ),
  h2: (
    <span>
      <svg
        height="12"
        width="12"
        fill="currentcolor"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z" />
      </svg>
      2
    </span>
  ),
  h3: (
    <span>
      <svg
        height="12"
        width="12"
        fill="currentcolor"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z" />
      </svg>
      3
    </span>
  ),
  h4: (
    <span>
      <svg
        height="12"
        width="12"
        fill="currentcolor"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z" />
      </svg>
      4
    </span>
  ),
  h5: (
    <span>
      <svg
        height="12"
        width="12"
        fill="currentcolor"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z" />
      </svg>
      5
    </span>
  ),
  h6: (
    <span>
      <svg
        height="12"
        width="12"
        fill="currentcolor"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z" />
      </svg>
      6
    </span>
  ),
  paragraph: (
    <svg
      height="12"
      width="12"
      fill="currentcolor"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1534 189v73q0 29-18.5 61t-42.5 32q-50 0-54 1-26 6-32 31-3 11-3 64v1152q0 25-18 43t-43 18h-108q-25 0-43-18t-18-43v-1218h-143v1218q0 25-17.5 43t-43.5 18h-108q-26 0-43.5-18t-17.5-43v-496q-147-12-245-59-126-58-192-179-64-117-64-259 0-166 88-286 88-118 209-159 111-37 417-37h479q25 0 43 18t18 43z" />
    </svg>
  ),
  image: (
    <svg
      height="12"
      width="12"
      fill="currentcolor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="8.5" cy="8.501" r="2.5" />
        <path d="M16,10c-2,0-3,3-4.5,3s-1.499-1-3.5-1c-2,0-3.001,4-3.001,4H19C19,16,18,10,16,10z" />
        <path d="M20,3H4C2.897,3,2,3.897,2,5v12c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V5C22,3.897,21.103,3,20,3z M20,17H4V5h16V17z" />
      </g>
    </svg>
  ),
  left: (
    <svg
      fill="none"
      height="12"
      width="12"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <line x1="17" x2="3" y1="10" y2="10" />
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="21" x2="3" y1="14" y2="14" />
      <line x1="17" x2="3" y1="18" y2="18" />
    </svg>
  ),
  right: (
    <svg
      fill="none"
      height="12"
      width="12"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <line x1="21" x2="7" y1="10" y2="10" />
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="21" x2="3" y1="14" y2="14" />
      <line x1="21" x2="7" y1="18" y2="18" />
    </svg>
  ),
  center: (
    <svg
      fill="none"
      height="12"
      width="12"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <line x1="18" x2="6" y1="10" y2="10" />
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="21" x2="3" y1="14" y2="14" />
      <line x1="18" x2="6" y1="18" y2="18" />
    </svg>
  ),
  link: (
    <svg
      fill="none"
      width="12"
      height="12"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      {" "}
      xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
      <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
    </svg>
  ),
};

const getItems = (
  editor,
  options = "p b h2 h3 h4 h5 h6 li img a",
  openModal
) => {
  const buttons = {
    left: {
      label: "left",
      icon: textIcons.left,
      action: () => editor.chain().focus().setTextAlign("left").run(),
      active: () => editor.isActive("paragraph"),
    },
    center: {
      label: "center",
      icon: textIcons.center,
      action: () => editor.chain().focus().setTextAlign("center").run(),
      active: () => editor.isActive("paragraph"),
    },
    right: {
      label: "right",
      icon: textIcons.right,
      action: () => editor.chain().focus().setTextAlign("right").run(),
      active: () => editor.isActive("paragraph"),
    },
    p: {
      label: "paragraph",
      icon: textIcons.paragraph,
      action: () => editor.chain().focus().setParagraph().run(),
      active: () => editor.isActive("paragraph"),
    },
    b: {
      label: "Bold",
      icon: textIcons.bold,
      action: () => editor.chain().focus().toggleBold().run(),
      active: () => editor.isActive("bold"),
    },
    h6: {
      label: "Heading6",
      icon: textIcons.h6,
      action: () => editor.chain().focus().toggleHeading({ level: 6 }).run(),
      active: () => editor.isActive("heading", { level: 6 }),
    },
    h5: {
      label: "Heading5",
      icon: textIcons.h5,
      action: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
      active: () => editor.isActive("heading", { level: 5 }),
    },
    h4: {
      label: "Heading4",
      icon: textIcons.h4,
      action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
      active: () => editor.isActive("heading", { level: 4 }),
    },
    h3: {
      label: "Heading3",
      icon: textIcons.h3,
      action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      active: () => editor.isActive("heading", { level: 3 }),
    },
    h2: {
      label: "Heading2",
      icon: textIcons.h2,
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      active: () => editor.isActive("heading", { level: 2 }),
    },
    li: {
      label: "List",
      icon: textIcons.list,
      action: () => editor.chain().focus().toggleBulletList().run(),
      active: () => editor.isActive("bulletList"),
    },
    a: {
      label: "Link",
      icon: textIcons.link,
      action: () => openModal(),
      active: () => editor.isActive("link"),
    },
  };

  return options
    .split(" ")
    .filter((o) => buttons[o])
    .map((o) => buttons[o]);
};

const selectImage = () => false;

const PopUpBar = ({ editor, options, openModal }) => {
  if (!editor) {
    return null;
  }

  const items = getItems(editor, options, openModal);
  return (
    <BubbleMenu editor={editor}>
      {items.map((item) => (
        <Button
          variant="link"
          size="sm"
          key={item.label}
          onClick={item.action}
          active={item.active()}
        >
          {item.icon}
        </Button>
      ))}
    </BubbleMenu>
  );
};

const FloatingBar = ({ editor, options, openModal, setOpen, setImageOpen }) => {
  if (!editor) {
    return null;
  }
  const items = getItems(editor, options, openModal);

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleImageClick = (event) => {
    setImageOpen(true);
  };

  return (
    <FloatingMenu editor={editor}>
      <IconButton
        size="md"
        variant="link"
        className="px-2"
        onClick={handleImageClick}
        icon="upload"
      />
      <IconButton
        size="md"
        variant="link"
        className="px-1"
        onClick={handleClick}
        icon="fileup"
      />

      {items.map((item) => (
        <Button
          variant="link"
          size="sm"
          key={item.label}
          onClick={item.action}
          active={item.active()}
        >
          {item.icon}
        </Button>
      ))}
    </FloatingMenu>
  );
};

const EditContentField = ({ content, update, options }) => {
  const { uploadToS3 } = useS3Upload();
  const [url, setUrl] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [imgCropOpen, setImgCropOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const editor = useEditor({
    extensions: [
      StarterKit,
      FloatingMenu,
      BubbleMenu,
      Image.configure({
        inline: false,
        HTMLAttributes: {
          class: "editor-image",
        },
      }),
      TextAlign.configure({ types: ["heading", "paragraph", "image"] }),
      Link.configure({
        openOnClick: false,
        validate: (href) => /^https?:\/\//.test(href),
      }),
      Youtube.configure({
        controls: true,
        inline: false,
        width: 280,
        height: 220,
      }),
    ],
    content,
    onUpdate({ editor }) {
      update(editor.getHTML());
    },
  });

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setUrl("");
  }, []);

  const saveLink = useCallback(() => {
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: "_blank" })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    editor.commands.blur();
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  const openModal = () => {
    setIsOpen(true);
  };

  const hiddenFileInput = React.useRef(null);
  const hiddenImageInput = React.useRef(null);

  const allowedFileTypes = ["docx", "pdf", "pptx"];
  const handleFileChange = async (event) => {
    setIsAlertVisible(false);
    let file = event.target.files[0];
    const fileType = file.name.split(".").pop();
    if (allowedFileTypes.includes(fileType)) {
      if (file.size / 1000000 < 25) {
        Global.setLoading(true);
        const { url } = await uploadToS3(file);
        Global.setLoading(false);
        let linkToAdd =
          '<a href="' +
          url +
          '" target="_blank">' +
          file.name.split(".")[0] +
          "</a>";
        editor.commands.insertContent(linkToAdd);
      } else {
        setIsAlertVisible(true);
        setFileContent("File size should not exceed 25MB");
      }
    } else {
      setIsAlertVisible(true);
      setFileContent(
        "Sorry, Unsupported file format. Supported file types include: docx, pdf, pptx. Please try again."
      );
    }
    event.target.value = "";
  };

  const addImage = async (dataUrl) => {
    const file = dataURIToFile(dataUrl, "insert.jpg");
    const image = await imageResizer(file, {
      width: 1800,
      height: 1200,
      filename: "insert.jpg",
    });
    const { url } = await uploadToS3(image);
    editor
      .chain()
      .focus()
      .setImage({ src: url, style: { width: "100%" } })
      .run();
      editor.commands.enter();
      editor.commands.focus('end')
  };

  const handleImageChange = (event) => {
    setIsAlertVisible(false);
    let file = event.target.files[0];
    if (file.type.match("image.*")) {
      setImgCropOpen(true);
      setImageFile(file);
    } else {
      setIsAlertVisible(true);
      setFileContent(
        "Sorry, Unsupported file format. Supported file types include: JPG, PNG. Please try again."
      );
    }
    event.target.value = "";
  };

  if (open) {
    hiddenFileInput.current.click();
    setOpen(false);
  }
  const closeAlertModal = () => {
    setIsAlertVisible(false);
  };

  if (imageOpen) {
    hiddenImageInput.current.click();
    setImageOpen(false);
  }

  // setTimeout(() => {
  //   closeAlertModal();
  // }, 3000);

  return (
    <div>
      <FloatingBar
        editor={editor}
        options={options}
        openModal={openModal}
        setOpen={setOpen}
        setImageOpen={setImageOpen}
        imgCropOpen={imgCropOpen}
        imageFile={imageFile}
      />
      <PopUpBar editor={editor} options={options} openModal={openModal} />
      <EditorContent className="editor-content mb-3" editor={editor} />
      <LinkModal
        url={url}
        showModal={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Link Modal"
        closeModal={closeModal}
        onChangeUrl={(e) => setUrl(e.target.value)}
        onSaveLink={saveLink}
        onRemoveLink={removeLink}
      />
      <input
        type="file"
        onChange={handleFileChange}
        ref={hiddenFileInput}
        hidden
      />
      <AlertModal
        showModal={isAlertVisible}
        closeModal={closeAlertModal}
        content={fileContent}
      />
      <input
        type="file"
        onChange={handleImageChange}
        ref={hiddenImageInput}
        hidden
      />
      {imgCropOpen && (
        <ImageCrop image={imageFile} update={(data) => addImage(data)} />
      )}
    </div>
  );
};

export default EditContentField;
