import { Button, Form, FormControl, Modal } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
export function LinkModal(props) {
  const { url, closeModal, onChangeUrl, onSaveLink, onRemoveLink, showModal } =
    props;
  const fieldRef = useRef(null);
  useEffect(() => {
    if (showModal && fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [showModal]);
  return (
    <Modal show={showModal} onHide={closeModal} size="lg" scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="link">
          <Form.Control
            autoFocus
            value={url}
            onChange={onChangeUrl}
            ref={fieldRef}
          />
        </Form.Group>
        <div>
        *Please ensure that video links are provided directly to the editor
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant="outline-danger" onClick={onRemoveLink}>
            Remove
          </Button>
          &nbsp;
          <Button
            variant="outline-success"
            onClick={onSaveLink}
            disabled={!(url.startsWith("/") || url.startsWith("http"))}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
