import React, { useRef, useState } from "react";
import { Modal, Image } from "react-bootstrap";
import { useS3Upload } from "next-s3-upload";
import Cropper from "react-cropper";
import IconButton from "./IconButton";
import IconButton2 from "./IconButton2";
import AlertModal from "../components/AlertModal";
const UploadAndCrop = ({
  update,
  label = "upload Image",
  image,
  aspectRatio = null,
  variant,
  size = "md",
  loading = false,
  hideUploadButton = false,
}) => {
  const [imageUrl, setImageUrl] = useState(image);
  const [isImage, setisImage] = useState(null);
  const [cropped, setCropped] = useState(null);
  const [status, setStatus] = useState("init");
  const cropperRef = useRef(null);
  const { FileInput, openFileDialog, uploadToS3 } = useS3Upload();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const handleClose = () => {
    setStatus("init");
  };
  const closeAlertModal = () => {
    setIsAlertVisible(false);
  };
  setTimeout(() => {
    closeAlertModal();
  }, 3000);
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    if (file.type.match("image.*")) {
      setisImage(true);
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
        setStatus("crop");
      };
      reader.readAsDataURL(file);
    } else {
      setisImage(false);
      setIsAlertVisible(true);
    }
    event.target.value = null;
  };

  const crop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const cropedDataUrl = cropper.getCroppedCanvas().toDataURL();
    const data = cropper.getData();
    const original = cropper.getImageData();
    if (original.width !== data.width || original.height !== data.height)
      setCropped(cropedDataUrl);
    setStatus("confirm");
  };

  const confirm = () => {
    update(cropped, cropperRef?.current);
    setStatus("init");
  };

  return (
    <>
      {!hideUploadButton && (
        <IconButton2
          size={size}
          variant="light"
          onClick={handleClick}
          icon="plusd"
          disabled={loading}
        >
          {label}
        </IconButton2>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {isAlertVisible && (
        <AlertModal
          showModal={isAlertVisible}
          closeModal={closeAlertModal}
          content="Sorry, Unsupported file format. Supported file types include: JPG, PNG. Please try again."
        />
      )}
      {isImage && (
        <>
          <Modal
            show={status === "crop"}
            onHide={handleClose}
            size="lg"
            scrollable
            backdrop="static"
            className="modal-top-z"
          >
            <Modal.Header closeButton>
              <Modal.Title>Crop your image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Cropper
                viewMode={1}
                src={imageUrl}
                style={{ height: 300, width: "100%" }}
                aspectRatio={aspectRatio}
                autoCropArea={1}
                guides={false}
                ref={cropperRef}
              />
            </Modal.Body>
            <Modal.Footer>
              <IconButton icon="crop" onClick={() => crop()}>
                Confirm Crop
              </IconButton>
            </Modal.Footer>
          </Modal>
          <Modal
            show={status === "confirm"}
            onHide={handleClose}
            size="lg"
            scrollable
          >
            <Modal.Header closeButton>
              <Modal.Title>Crop your image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Image src={cropped} fluid />
            </Modal.Body>
            <Modal.Footer>
              <IconButton
                variant="outline-secondary"
                icon=""
                onClick={() => {
                  setImageUrl(null);
                  setisImage(false);
                  setCropped(null);
                  setStatus("init");
                }}
              >
                Cancel
              </IconButton>
              <IconButton icon="check" onClick={() => confirm()}>
                Save Image
              </IconButton>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default UploadAndCrop;
