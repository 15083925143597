import React, { useEffect, useRef, useState } from 'react';
import {
    Modal, Image,
} from 'react-bootstrap';
import Cropper from 'react-cropper';
import IconButton from './IconButton';

const ImageCrop = ({
    update, image, aspectRatio = null
}) => {
    const [imageUrl, setImageUrl] = useState(image);
    const [cropped, setCropped] = useState(null);
    const [status, setStatus] = useState('init');
    const cropperRef = useRef(null);

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrl(reader.result);
                setStatus('crop');
            };
            reader.readAsDataURL(image);
        }
    }, [image]);

    const handleClose = () => {
        setStatus('init');
    };


    const crop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        const cropedDataUrl = cropper.getCroppedCanvas().toDataURL();
        const data = cropper.getData();
        const original = cropper.getImageData();
        if (original.width !== data.width || original.height !== data.height) setCropped(cropedDataUrl);
        setStatus('confirm');
    };

    const confirm = () => {
        update(cropped, cropperRef?.current);
        setStatus('init');
    };

    return (
      <>
        <Modal
          className="modal-top-z"
          show={status === "crop"}
          onHide={handleClose}
          size="lg"
          scrollable
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Crop your image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Cropper
              viewMode={1}
              src={imageUrl}
              style={{ height: 300, width: "100%" }}
              aspectRatio={aspectRatio}
              autoCropArea={1}
              guides={false}
              ref={cropperRef}
            />
          </Modal.Body>
          <Modal.Footer>
            <IconButton icon="crop" onClick={() => crop()}>
              Confirm Crop
            </IconButton>
          </Modal.Footer>
        </Modal>
        <Modal
          show={status === "confirm"}
          onHide={handleClose}
          size="lg"
          scrollable
          className="modal-top-z"
        >
          <Modal.Header closeButton>
            <Modal.Title>Crop your image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src={cropped} fluid />
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              variant="outline-secondary"
              icon=""
              onClick={() => setStatus("init")}
            >
              Cancel
            </IconButton>
            <IconButton icon="check" onClick={() => confirm()}>
              Save Image
            </IconButton>
          </Modal.Footer>
        </Modal>
      </>
    );
};

export default ImageCrop;
