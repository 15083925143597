import React from 'react';
import {
  Modal,Alert
} from 'react-bootstrap';

const AlertModal = ({showModal, closeModal, content}) =>{
    return (     
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Body>
          <Alert variant="primary">{content}</Alert>
          </Modal.Body>
        </Modal>    
    );
}

export default AlertModal;